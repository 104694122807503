import React, { useState } from "react";
import classnames from "classnames";
import {
  Container,
  AppBar,
  Menu,
  MenuItem,
  Box,
  Toolbar,
  IconButton,
  CssBaseline,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

// import styles from "components/Signup/styles";
import { Link } from "react-router-dom";

import Drawer from "components/Drawer";

import { pages, settings } from "./menuConfig";

import { useStyles } from "./styles";

const activePages = pages.filter((page) => page.active);

const Navbar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (e) => {
    setAnchorElNav(e.currentTarget);
  };
  // const handleOpenUserMenu = (e) => {
  //   setAnchorElUser(e.currentTarget);
  // };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Container maxWidth="xl" sx={{ p: "0px !important" }}>
      <AppBar
        position="fixed"
        sx={{ background: theme.background1 }}
        style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
      >
        <CssBaseline />
        <Toolbar disableGutters>
          <Link to="/">
            <IconButton
              size="large"
              edge="start"
              sx={{ color: theme.palette.secondary.light }}
            >
              <img src="/logo192.png" style={{ width: 45 }} alt="logo" />
            </IconButton>
          </Link>
          <Link to="/" className={classes.logoLink}>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: "flex", md: "flex" } }}
              color={theme.palette.primary.dark}
            >
              EFF
            </Typography>
          </Link>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
                // backgroundColor: "blue",
              }}
              PaperProps={{
                style: {
                  backgroundColor: theme.palette.background.paperSolid,
                },
              }}
            >
              {activePages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Link
                    to={page.url}
                    className={classnames(classes.link, classes.menuLink)}
                  >
                    <Typography textAlign="center">{page.name}</Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {/* <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip> */}
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting, i) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {isMobile ? (
            <Drawer />
          ) : (
            <div className={classes.navlinks}>
              {activePages.map((page, i) => (
                <Link key={i} to={page.url} className={classes.link}>
                  {page.name}
                </Link>
              ))}
            </div>
          )}
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            <MenuIcon fontSize="large" />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Container>
  );
};
export default Navbar;
