export const styles = {
  link: {
    color: "rgb(255,128,128)",
    fontSize: 50,
    fontWeight: "bold",
    textShadow: "0 0 5px #000000",
  },
  donate: {
    display: "flex",
    fontSize: 36,
    paddingLeft: 0,
    height: 200,
    backgroundImage: "url(/donation.png)",
    backgroundSize: "100px 100px",
    backgroundPosition: "bottom center",
    backgroundRepeat: "no-repeat",
    justifyContent: "center",
    alignItems: "start",
  },
  img: {
    width: "100%",
    height: "100%",
  },
};

export default styles;
