/** @jsxImportSource @emotion/react */
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";

import styles from "./styles";

const Profile = ({ name, title, infoItems, photo }) => (
  <Card sx={styles.root} raised>
    <CardHeader
      sx={styles.title}
      title={name}
      subheader={<Typography style={styles.subTitle}>{title}</Typography>}
    />
    <CardMedia
      component="img"
      height="194"
      image={photo ?? "https://placehold.co/345x194/orange/black?text=MugShot"}
      alt="Profile Pic"
      sx={{
        width: "80%",
        height: 345,
        margin: "0 auto",
      }}
    />
    <CardContent sx={styles.profileInfo}>
      <Typography variant="body2" color="lightyellow">
        <ul>
          {infoItems?.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      </Typography>
    </CardContent>
  </Card>
);

export default Profile;
