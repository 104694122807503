/** @jsxImportSource @emotion/react */
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import Section from "components/Section";

import styles from "./styles";

const Donate = () => (
  <Container maxWidth="xl">
    <Section>
      <Typography variant="h4" sx={{ fontWeight: "100", textAlign: "center" }}>
        <p>Help bring child education back into the arms of parents!</p>
      </Typography>
    </Section>
    <Section title="Donate Information:" style={{ padding: 10 }}>
      <Typography variant="h5" sx={{ textAlign: "center" }}>
        <div style={styles.donate}>
          <a
            style={styles.link}
            target="_blank"
            rel="noreferrer"
            href="https://secure.anedot.com/educational-freedom-foundation/1115c871-b9c8-4ab2-974e-6efc16877548"
          >
            Donate Here
          </a>
        </div>
      </Typography>
    </Section>
    <Section title="Funding:">
      <Typography variant="h5" sx={{ fontWeight: "fontWeightLight" }}>
        <p>
          Thank you to our donors. The Educational Freedom Foundation is a
          privately funded foundation supporting the alternative educational
          movement. We strive to provide the resources and early capital needed
          to help micro schools, homeschool, and other unconventional learning
          environments start, grow, and thrive.{" "}
        </p>
      </Typography>
    </Section>
    <Section title="Process:">
      <Typography variant="h5" sx={{ fontWeight: "fontWeightLight" }}>
        <p>
          Your donations will financially support the alternative education
          environments and also sponsor students through scholarships. This is
          accomplished through the application process reviewed by our board,
          schools and parents.
        </p>
      </Typography>
    </Section>
  </Container>
);

export default Donate;
