const styles = {
  root: {
    width: "45%",
    minWidth: 345,
    marginBottom: 5,
    backgroundColor: "#212121",
  },
  p: {
    margin: 0,
  },
  img: {
    width: "100%",
    height: "100%",
  },
  title: {
    color: "#f9f19e",
    backgroundColor: "#212121",
    textAlign: "center",
  },
  profilePicContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f9f19e",
  },
  profilePic: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 150,
    height: 150,
  },
  profileInfo: { minHeight: 130, backgroundColor: "#212121" },
};

export default styles;
