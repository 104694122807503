import React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

const Copyright = () => (
  <Typography
    sx={{ marginTop: 5, marginBottom: 5 }}
    variant="body2"
    color="#cae3fe"
    align="center"
  >
    {"Copyright © "}
    <Link color="#cae3fe" href="#">
      Educational Freedom Foundation
    </Link>{" "}
    {new Date().getFullYear()}
    {"."}
  </Typography>
);

export default Copyright;
