// Add profiles here
const data = [
  {
    name: "Josiah Enyart",
    title: "President/CEO",
    photo: "/profiles/josiahenyart.jpg",
    infoItems: ["Husband & Father of 3", "Educational Entrepreneur", "Speaker"],
  },
  {
    name: "Chris Fees",
    title: "Vice President",
    photo: "/profiles/chrisfees.jpg",
    infoItems: [
      "Husband and Homeschool father of 8",
      "HSLDA Sales Force Representative",
    ],
  },
  {
    name: "Michelle Fees",
    title: "Secretary",
    photo: "/profiles/michellefees.jpg",
    infoItems: [
      "Homeschool mother of 8",
      "15+ years of nonprofit accounting experience",
    ],
  },
  {
    name: "Lynn McLarty",
    title: "Treasurer",
    photo: "/profiles/lynnmclarty.jpg",
    infoItems: [
      "Husband, Father of 5, Grandfather of 11",
      "Retired Navy and Data Services Technician",
    ],
  },
  {
    name: "Travis Olson",
    title: "Board Member",
    photo: "/profiles/travisolson.jpg",
    infoItems: [
      "Husband and father of 3",
      "Educator and University Model Parent",
    ],
  },
  {
    name: "Becka Eisman",
    title: "Board Member",
    photo: "/profiles/beckaeisman.jpg",
    infoItems: ["40 years as Human Resource Manager/Director"],
  },
];

export default data;
